<template>
  <div style="color: #000;height: 100%;">
    <van-nav-bar
      title="合作协议书"
      v-if="showDom == false"
      left-arrow
      :fixed="true"
      :z-index="999"
      @click-left="back"
    ></van-nav-bar>
    <div style="height: 100%;" v-if="showDom == false" class="tophes">
      <div style="position: relative;">
        <img class="qyimg" :src="list.contractPic" alt />
        <div class="hes1">
          <div class="h1">{{list.checkStore}}</div>
          <div class="h2">{{list.sellCompany}}</div>
          <div class="h2">{{list.ownerName}}</div>
          <div class="h2">{{list.idNumber}}</div>
          <div class="h2">{{list.vin}}</div>
          <div class="h2">{{list.carNo}}</div>
          <div class="h2 h2_c">{{list.carBrand}}{{list.standard}}</div>
        </div>
        <div class="hes2">
          <div class="gongli gonm">{{list.starDriveMiles}}</div>
          <div class="gongli2 gonm gongli2_a">
            <div class="left mian1 gonm">{{list.endDriveMiles}}</div>
            <div class="left mian2 gonm">{{starDate.nian}}</div>
           
          </div>
          <div class="gongli2 newgongli" >
             <div class="left mian3 gonm">{{starDate.yue}}</div>
            <div class="left mian4 gonm">{{starDate.ri}}</div>
            <div class="left mian5 gonm">{{endDate.nian}}</div>
            <div class="left mian3 gonm">{{endDate.yue}}</div>
            <div class="left mian3_a gonm">{{endDate.ri}}</div>
          </div>
        </div>
      </div>
      <div style="position: relative;">
        <img class="qyimg" src="../../assets/img/heyue2.png" alt />
        <img class="gongimg" src="../../assets/img/gong1.png" alt />
        <img class="gongimg2" :src="list.sealPic" alt />
        <div class="bos1">
          <div class="dianname">{{list.checkStore}}</div>
          <div class="qmdiv">
            <img class="qmimg" :src="list.checkSign" alt />
          </div>

          <div class="riqi">{{list.checkDate}}</div>
        </div>
        <div class="bos2">
          <div class="dianname">{{list.sellCompany}}</div>
          <div class="qmdiv">
            <img class="qmimg" :src="list.sellSign" alt />
          </div>
          <div class="riqi">{{list.sellSignDate}}</div>
        </div>
        <div class="bos3">
          <div class="qmdiv">
            <img class="qmimg3" :src="list.customerSign" alt />
          </div>
          <div class="riqi">{{list.customerSignDate}}</div>
        </div>
      </div>
    </div>
    <div class="qianming" v-if="showDom == true">
      <div style="border: 2px solid #E6E6E6 ;background-color: white;w-100 h-100">
        <vue-esign
          ref="esign"
          :height="height"
          class="w-100 h-100"
          :isCrop="isCrop"
          :lineWidth="lineWidth"
          :lineColor="lineColor"
          :bgColor.sync="bgColor"
        />
      </div>

      <van-icon class="shanchuico" name="close" @click.stop="showDom = false" />
      <div
        v-if="showDom"
        class="abs w-100 z-999 p-15"
        style="transform: rotate(90deg);top: 50%;left: -35%;"
      >
        <div class="w-100 m-t-10 f">
          <van-button class="m-5" block type="primary" size="large" @click="handleGenerate()">同意</van-button>
          <van-button class="m-5" block type="default" size="large" @click="handleReset">重新输入</van-button>
        </div>
      </div>
    </div>

    <van-button
      class="qiananniu"
      v-if="showDom == false&&list.customerSign==null&& uesisok != 'true'"
      @click="qianming"
      size="small"
      type="primary"
    >去签名</van-button>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
import { detailsuid, carsign,ershoucardetails } from "../../api/check.js";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
import vueQr from "vue-qr";
export default {
  components: { vueQr },
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      actv: 1,
      list: {},
      starDate: {},
      endDate: {},
      search: "",
      loading: false,
      total: 0,
      finished: false,
      id: this.$route.query.id,
      uesisok:this.$route.query.uesisok,
      isWxMini: "",
      //--------------
      imgs: null,
      imgss: null,
      showDom: false,
      baseData: "",
      // --------------
      lineWidth: 11,
      lineColor: "#000000",
      bgColor: "",

      resultImg: "",
      isCrop: false
    };
  },
  methods: {
    qianming() {
      this.showDom = true;
    },
    handleReset() {
      console.log(this.$refs.esign);
      this.$refs.esign.reset();
    },
    //点击确定
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then(res => {
          console.log(res);
          this.baseData = res;
          this.submit();
        })
        .catch(err => {
          //   this.$toast("请签名后再生成签字图片");
        });
    },
    submit() {
      this.toast = this.$toast({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
        message: "签名生成中..."
      });

      let _this = this;
      // var canvas = $(".jSignature");
      // var dataURL = canvas.get(0).toDataURL();
      this.rotateBase64Img(this.baseData, 270);
      setTimeout(() => {
        this.imgss = this.baseData;
        _this.toast.message = "签名上传中...";
        console.log(this.baseData);
        // var file = _this.dataURLtoFile(_this.baseData, "img");

        var file = _this.dataURLtoFile(_this.baseData, ".png");

        _this.updata(file);
      }, 300);
    },
    updata(file) {
      let _this = this;
      var formData = new FormData();
      formData.append("file", file);
      userModel.uploadPicturedan(formData).then(e => {
        _this.toast.clear();
        this.shanchuangtupian(e.data);
      });
    },
    //保存合同签名图
    shanchuangtupian(url) {
      var data = {
        id: this.list.id,
        type: 1,
        sign: url,
        contractPic:this.list.contractPic,
        sealPic:this.list.sealPic,
      };
      carsign(data).then(e => {
        // _this.$toast("请先完成签名！");
        if (e.code == 200) {
          this.$toast("签名成功！");
          this.onLoad();
          this.showDom = false;
        }
      });
    },
    //				旋转base64
    rotateBase64Img(src, edg) {
      let _this = this;
      var edgData = "";
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var imgW; //图片宽度
      var imgH; //图片高度
      var size; //canvas初始大小
      if (edg % 90 != 0) {
        console.error("旋转角度必须是90的倍数!");
        throw "旋转角度必须是90的倍数!";
      }
      edg < 0 && (edg = (edg % 360) + 360);
      const quadrant = (edg / 90) % 4; //旋转象限
      const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 }; //裁剪坐标
      var image = new Image();
      image.crossOrigin = "anonymous";
      image.src = src;
      image.onload = function() {
        imgW = image.width;
        imgH = image.height;
        size = imgW > imgH ? imgW : imgH;
        canvas.width = size * 2;
        canvas.height = size * 2;
        switch (quadrant) {
          case 0:
            cutCoor.sx = size;
            cutCoor.sy = size;
            cutCoor.ex = size + imgW;
            cutCoor.ey = size + imgH;
            break;
          case 1:
            cutCoor.sx = size - imgH;
            cutCoor.sy = size;
            cutCoor.ex = size;
            cutCoor.ey = size + imgW;
            break;
          case 2:
            cutCoor.sx = size - imgW;
            cutCoor.sy = size - imgH;
            cutCoor.ex = size;
            cutCoor.ey = size;
            break;
          case 3:
            cutCoor.sx = size;
            cutCoor.sy = size - imgW;
            cutCoor.ex = size + imgH;
            cutCoor.ey = size + imgW;
            break;
        }
        ctx.translate(size, size);
        ctx.rotate((edg * Math.PI) / 180);
        ctx.drawImage(image, 0, 0);
        var imgData = ctx.getImageData(
          cutCoor.sx,
          cutCoor.sy,
          cutCoor.ex,
          cutCoor.ey
        );
        if (quadrant % 2 == 0) {
          canvas.width = imgW;
          canvas.height = imgH;
        } else {
          canvas.width = imgH;
          canvas.height = imgW;
        }
        ctx.putImageData(imgData, 0, 0);
        _this.baseData = canvas.toDataURL();
      };
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime
      });
    },
    // ---------------
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      const data = {
        id: this.id
        // uid: 1661
      };
      ershoucardetails(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
          if (e.data.starDate) {
            var starDate = e.data.starDate.split("-");
            this.starDate = {
              nian: starDate[0],
              yue: starDate[1],
              ri: starDate[2]
            };
          }
          if (e.data.endDate) {
            var endDate = e.data.endDate.split("-");
            this.endDate = {
              nian: endDate[0],
              yue: endDate[1],
              ri: endDate[2]
            };
          }
          if (e.data.checkDate) {
            this.list.checkDate = e.data.checkDate.substring(0, 10);
          }
          if (e.data.customerSignDate) {
            this.list.customerSignDate = e.data.customerSignDate.substring(0, 10);
          }
          if (e.data.sellSignDate) {
            this.list.sellSignDate = e.data.sellSignDate.substring(0, 10);
          }
        })

        .catch(() => loading.clear());
    },

    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
    this.height = window.innerHeight;
    console.log(this.height);
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.tophes {
  margin-top: 46px;
  position: relative;
  font-size: 15px;
  color: #000;
}

.qyimg {
  width: 100%;
}

.hes2 {
  position: absolute;
  left: 0;
  top: 0;
  margin-top:481px;
  font-weight: bold;
}
.gongli {
  width: 58px;
  margin-left: 170px;
}
.gongli2 {
  overflow: hidden;
  height: 18px;
  margin-top: 1px;
 
}
.newgongli{
  margin-top: 8px;
}
.mian1 {
  width: 56px;
  margin-left: 65px;
  margin-top: 4px;
  
}
.gonm {
  line-height: 18px;
  text-align: center;
}
.mian2 {
  width: 50px;
  margin-left: 158px;
   margin-top: 4px;
}
.mian3 {
  width: 25px;
  margin-left: 33px;
}
.mian3_a {
  width: 25px;
  margin-left: 25px;
}
.mian4 {
  width: 27px;
  margin-left: 28px;
}
.mian5 {
  width: 50px;
  margin-left: 45px;
}
.gongli {
  margin-top: 1px;
}
.hes1 {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 53px;
  font-size: 15px;
}
.h1 {
  margin-left: 175px;
  line-height: 24px;
  min-height: 24px;
}
.h2 {
  margin-left: 175px;
  line-height: 25px;
  // margin-top: 5px;
  min-height: 25px;
}
.h2_c{
    margin-left: 195px;
  // margin-top: 1px;
}
.bostt {
  position: absolute;
  bottom: 0;
  left: 0;
}
.gongimg {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  margin-right: 60px;
}
.gongimg2 {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  margin-right: 60px;
  margin-top: 145px;
}
.bos1 {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 65px;
}
.bos2 {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 213px;
}
.bos3 {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 351px;
}
.dianname {
  margin-left: 135px;
  margin-top: 0px;
  line-height: 24px;
  min-height: 24px;
}
.qmimg {
  width: 85px;
  margin-left: 225px;
  display: block;
}
.qmdiv {
  height: 46px;
}
.qmimg3 {
  width: 70px;
  margin-left: 120px;
  margin-top: 4px;
}
.riqi {
  margin-left: 105px;
  line-height: 23px;
}
.qiananniu {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  line-height: 36px;
  height: 36px;
  
  font-size: 16px;
}
.shanchuico {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
  font-size: 36px;
  color: #666;
}
</style>